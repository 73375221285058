<template>
  <div>
    <div class="auth-layout-f row align-content--center resp-auth height-new"  :style='"background-image: url("+pathDoSite+"storage/"+fundo+");"'
    v-if="this.fundo">
      <div class="flex xs12 pa-3">
        <div class="d-flex justify--center">
          <va-card class="auth-layout__card" style="border-radius: 3px !important;">
            <va-card-content>

              <div class="flex xs12 flex-center">
                <router-link class="flex-center" to="/">
                  <div>
                    <!--/img/logo-login.png-->
                    <img src="/img/logo.png" v-if="logo == null" style="width: 190px;margin-top: 21px;">
                    <img :src="pathDoSite+'storage/'+logo" v-else  style="width: 100%;/*margin-top: 21px;*/">
                  </div>
                </router-link>
              </div>

              <div class="pa-3">
                <router-view />
              </div>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>

    <div class="auth-layout row align-content--center resp-auth" v-else>
      <div class="flex xs12 pa-3">
        <div class="d-flex justify--center">
          <va-card class="auth-layout__card" style="border-radius: 3px !important;">
            <va-card-content>

              <div class="flex xs12 flex-center">
                <router-link class="flex-center" to="/">
                  <div v-if="temp == 1">
                    <!--/img/logo-login.png-->
                    <img src="/img/logo.png" v-if="logo == null" style="width: 190px;margin-top: 21px;">
                    <img :src="pathDoSite+'storage/'+logo" v-else  style="width: 190px;margin-top: 21px;">
                  </div>
                </router-link>
              </div>

              <div class="pa-3">
                <router-view />
              </div>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuesticLogo from "@/components/vuestic-logo";
import { Configuracao } from "@/class/configuracao.js";
import axios from 'axios';

export default {
  name: "AuthLayout",
  components: { VuesticLogo },
  data() {
    return {
      selectedTabIndex: 0,
      logo:null,
      fundo:null,
      pathDoSite: axios.defaults.baseURL.replace('api/v1',''),
      temp:0,
    };
  },
  computed: {
    tabIndex: {
      set(tabName) {
        this.$router.push({ name: tabName });
      },
      get() {
        return this.$route.name;
      }
    }
  },
  async beforeMount() {
    let dt0 = await Configuracao.logoAcessoLoginFundo();
    this.fundo  = dt0.data.logo;
    let dt = await Configuracao.logoAcessoLogin();
    this.logo  = dt.data.logo;
    setTimeout(() => {
      this.temp = 1;
    }, 100);
  },
};
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;
 // background-image: linear-gradient(to right, var(--va-background), var(--va-white));
  background-image: url("/img/bkg.png");
  background-color: #536ff8;
  background-size: 100% auto;

  &__card {
    width: 100%;
    max-width: 600px;
  }
}

.auth-layout-f {
  min-height: 100vh;
  background-color: #536ff8;
  background-size: 100% auto;

  &__card {
    width: 100%;
    max-width: 600px;
  }
}
</style>

<style>
  .resp-auth{
    max-width: 100% !important;
  }
  @media (max-width:992px){
    .resp-auth{
      max-width: 106% !important;
    }
  }

  .height-new{
    background-size: 100% 100% !important;
  }
  @media (max-width:767px){
    .height-new{
      background-size: 100% 50% !important;
    }
  }
</style>
