export default {
  root: {
    name: '/',
    displayName: 'navigationRoutesProfessor.home',
  },
  routes: [
    {
      name: 'ambiente-professor',
      displayName: 'Area de Trabalho',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'calendarioCoordenador',
          displayName: 'Calendario Letivo',
        },
        {
          name: 'coordenacao-escola',
          displayName: 'Painel de Controler',
        },
        {
          name: 'coordenacao-servidores',
          displayName: 'Professores',
        },
        /*{
          name: 'coordenacao-professores',
          displayName: 'Professores',
        },*/
        {
          name: 'coordenacao-listaalunos',
          displayName: 'Alunos',
        },
        {
           name: 'coordenacao-turma-hab',
          displayName: 'Turmas',
        },
      ]
    }
  ],
}
