import axios from 'axios'

export class Configuracao {
  static async obtemTodos () {
    return axios.get('/configuracao')
  }
  static async obtemOne () {
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get('configuracao/one/'+ano);
  }
  static async criarConfiguracao (payload) {
    return axios.post('configuracao', payload)
  }
  static async criarConfiguracaoPorSegmento (payload) {
    return axios.post('configuracao/porsegmento', payload)
  }
  static async ativarConfiguracao (payload) {
    return axios.post('configuracao/ativar',payload);
  }
  static async permitirAtivacaoConfiguracao(payload) {
    return axios.post('configuracao/permitir/ativacao',payload);
  }

  //como estava antes
  // static async buscarConfiguracao(payload) {
  //   return axios.get('configuracao/one/'+payload);
  // }
  static async buscarConfiguracao() {
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get('configuracao/one/'+ano);
  }


  static async buscarConfiguracaoComTipo(tipo, eja2) {
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get('configuracao/one/tipo/'+tipo+'/eja2/'+eja2+'/ano/'+ ano);
  }

  static async editarConfiguracaoConfig(payload) {
    return axios.put('configuracao/'+ payload.id, payload);
  }

  static async editarConfiguracaoNotas(payload) {

    return axios.put('configuracao/porsegmento/'+payload.id, payload);
  }

  static async buscarConfiguracaoPeloSegmento(segmento_id) {
    return axios.get('configuracao/one/by/segmento/'+segmento_id);
  }

  static async allLogo(){
    return axios.get('configuracao-logo');
  }

  static async allLogoShow(tipo){
    return axios.get('configuracao-logo/'+tipo);
  }

  static async logoAcessoLogin(){
    return axios.get('logo-acessologin');
  }

  static async logoAcessoLoginFundo(){
    return axios.get('logo-acessologin-fundo');
  }

  static async deleteLogo(tipo){
    return axios.delete('configuracao-logo/'+tipo);
  }

  static async gravacaoLogo (t,tipo, arquivo) {
    let config = {
      header : {
       'Content-Type' : 'multipart/form-data'
     }
    }

    if(t == 0){
      return axios.post('configuracao-logo/'+tipo, arquivo, config);
    }else{
      return axios.post('configuracao-logo/editar/'+tipo, arquivo, config);
    }
  }


  //configuracao do sistema
  static async buscarConfiguracaoDoSistama(){
    return axios.get('config/sistema');
  }


}
